let env = {
  TRADE_URL:
    process.env.REACT_APP_TRADE_URL || "http://localhost:3001/api/trade",
  CANDLES_URL:
    process.env.REACT_APP_CANDLES_URL || "http://localhost:3003/api/candles",
  ADMIN_URL:
    process.env.REACT_APP_ADMIN_URL || "http://localhost:5000/admintool/api",
};

export { env };
