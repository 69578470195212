import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";

async function getAnnouncement() {
  try {
    return await axios.get(`${env.ADMIN_URL}/announcement`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: "application/json",
      },
    });
  } catch (err) {
    return errorCustom(err);
  }
}

export { getAnnouncement };
