import React, { useState, useEffect } from "react";
import { updateAnnouncement } from "store/modules/preferences/actions";
import { getAnnouncement } from "services/admin-service";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header";
import NavBar from "./components/NavBar";
import AnnouncementBar from "./components/AnnouncementBar";

const UserLayout = (props) => {
  const { children } = props;
  const preferences = useSelector((state) => state.preferences);
  const dispatch = useDispatch();
  const loadAnnouncement = async (_) => {
    const res = await getAnnouncement();

    dispatch(updateAnnouncement(res?.data?.data));
  };

  useEffect((_) => {
    loadAnnouncement();
    setInterval((_) => {
      loadAnnouncement();
    }, 30000);
  }, []);

  return (
    <>
      <AnnouncementBar announcement={preferences.announcement} />
      <Header style={preferences.announcement.status === true && { top: 35 }} />
      <main>{children}</main>
      <NavBar />
    </>
  );
};

export default UserLayout;
