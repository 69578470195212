import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";
import { getEventId } from "utils/eventIdGenerator.js";
async function loadTutorial() {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.TRADE_URL}/tutorial`,
      { eventId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

export { loadTutorial };
