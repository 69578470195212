import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";
import { getEventId } from "utils/eventIdGenerator.js";

async function getEnvironment() {
  const eventId = getEventId();
  try {
    return await axios.post(`${env.TRADE_URL}/frontend-environment`, {
      eventId,
    });
  } catch (err) {
    return errorCustom(err);
  }
}

async function validateDevAccounts(accounts) {
  const eventId = getEventId();
  try {
    return await axios.post(`${env.TRADE_URL}/user/check-dev-user`, {
      ...accounts,
      eventId,
    });
  } catch (err) {
    return errorCustom(err);
  }
}

async function validateAccounts(iframeToken) {
  const eventId = getEventId();
  try {
    return await axios.post(`${env.TRADE_URL}/user/check-user`, {
      iframeToken,
      eventId,
    });
  } catch (err) {
    return errorCustom(err);
  }
}

async function getUser(account) {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.TRADE_URL}/user/load-user`,
      { account, eventId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

async function changeFavorites(favorites, exchangeAccountId, exchange) {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.TRADE_URL}/user/favorites`,
      {
        favorites: favorites,
        exchangeAccountId: exchangeAccountId,
        exchange: exchange,
        eventId,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

async function changeFlag(tutorialFlag) {
  const eventId = getEventId();

  try {
    return await axios.post(
      `${env.TRADE_URL}/user/tutorialFlag`,
      { tutorialFlag, eventId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

export {
  getEnvironment,
  getUser,
  changeFlag,
  changeFavorites,
  validateDevAccounts,
  validateAccounts,
};
