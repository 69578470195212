import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";
import { getEventId } from "utils/eventIdGenerator.js";

async function getPrices(exchange) {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.TRADE_URL}/pricing/prices?exchange=${exchange}`,
      { eventId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

async function getOrderBook(exchange, symbol) {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.TRADE_URL}/pricing/order-book?exchange=${exchange}&symbol=${symbol}`,
      { eventId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

export { getOrderBook, getPrices };
