import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";
import { getEventId } from "utils/eventIdGenerator.js";
async function getPriceLists(body) {
  const eventId = getEventId();
  try {
    return await axios.post(
      `${env.CANDLES_URL}/price-list`,
      { ...body, eventId },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

async function getGraphData(body) {
  const eventId = getEventId();

  try {
    return await axios.post(
      `${env.CANDLES_URL}/graph-data`,
      { ...body, eventId },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

export { getGraphData, getPriceLists };
