import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";
import { getEventId } from "utils/eventIdGenerator.js";
async function loadMarkets(exchange, symbol) {
  const eventId = getEventId();

  try {
    return await axios.post(
      `${env.TRADE_URL}/market-data`,
      {
        exchange,
        symbol,
        eventId,
      },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
  } catch (err) {
    return errorCustom(err);
  }
}

export { loadMarkets };
