import React from "react";

export default function AnnouncementBar({ announcement }) {
  return (
    announcement.status && (
      <div
        style={{
          background: "rgb(52, 97, 206)",
          padding: 7,
          textAlign: "center",
          fontSize: 14,
          fontWeight: "bold",
          color: "white",
          position: "fixed",
          zIndex: "1000",
          width: "100%",
        }}
      >
        {announcement.message}
      </div>
    )
  );
}
